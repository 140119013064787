import React, { createContext, useContext, useReducer } from 'react';

const StoreContext = createContext();
const initialState = {token:''};


function reducer(state, action){
  if (action.type==='login'){
    return {token:action.message}
  }
  if (action.type==='logout'){
    return {token:''}
  }
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={{state, dispatch}}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = () => useContext(StoreContext);