//export const baseURL = 'http://207.148.25.204:8080';
export const baseURL = 'http://localhost:8080';
//export const baseWebPage = 'http://207.148.25.204/#'
export const baseWebPage = 'http://localhost:3000/#'
export const tableStyles = {
  borderRadius: '4px',
  borderWidth: '3px',
  borderColor: '#000055',
  borderStyle: 'solid',
  padding: '30px',
  width:'400px'
}

export const tdStyles = {
  borderWidth: '1px',
  borderColor: '#000055',
  borderStyle: 'solid',
  borderCollapse: 'collapse'
}

export const buyShitStyles = {
  borderRadius: '4px',
  borderWidth: '3px',
  borderColor: '#777777',
  borderStyle: 'solid',
  width: '400px',
  padding: '30px',
}

export const borderMaker = {
  padding: '50px'
}