import './App.css';
import React, {useState} from "react";
import axios from 'axios'
import {useStore} from "./store";
import bcrypt from 'bcryptjs'
import {baseURL, baseWebPage, buyShitStyles} from "./utils";
import useLocalStorage from "./useLocalStorage";

function App() {
  const [token, setToken] = useLocalStorage('token', '')
  const [signingup, setSigningup] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginFail, setLoginFail] = useState(false)
  const {state, dispatch} = useStore()
  return (

    <div style={buyShitStyles}>
      <button onClick={() => {
        setSigningup(!signingup)
      }}>
        {signingup ? 'Have an account? Go to Login.' : 'No account? Click here to sign up.'}
      </button>
      <div>
        Username:
        <input type={'text'} onChange={event => setUsername(event.target.value)} value={username}/>
      </div>
      <div>
        Password:
        <input type={'password'} onChange={event => {
          setPassword(event.target.value)
        }
        } value={password}/>
      </div>
      <button onClick={async () => {
        async function log() {
          const token = await axios.post(baseURL + '/login', {
            username: username,
            password: password
          })
          dispatch({type: "login", message: token})
          if (token.data) {
            setToken(token.data)
            document.location = `${baseWebPage}/profile/`
          }else{
            setLoginFail(true)
          }
        }

        async function signup() {
          console.log('running signup')
          const salt = await bcrypt.genSalt(10);
          const newPass = await bcrypt.hash(password, salt);
          await axios.post(`${baseURL}/signup`, {username: username, password: newPass})
          alert('You can now login with this account')
          setSigningup(false)
        }
        if (signingup) {
          await signup()
        }else {await log()}
      }}>
        {!signingup ? 'Login' : 'Signup'}
      </button>
      <div>
        {loginFail ? 'Login failed, try again.' : null}
      </div>

    </div>
  );
}

export default App;