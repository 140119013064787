import './App.css';
import React, {useState, useEffect} from "react";
import axios from 'axios'
import {roundPrice} from "./roundPrice";
import {useStore} from "./store";
import {baseURL, baseWebPage} from "./utils";
import useDebounce from "./useDebounce";
import {tableStyles, tdStyles, borderMaker, buyShitStyles} from './utils'
import useLocalStorage from "./useLocalStorage";

function Profile() {
  const [token, setToken] = useLocalStorage('token', '')
  const [updateTick, setUpdateTick] = useState(0)
  const [holdings, setHoldings] = useState([])
  const [cash, setCash] = useState(0)
  const [buying, setBuying] = useState(true)
  const [ticker, setTicker] = useState('')
  const debouncedTicker = useDebounce(ticker, 20)
  const [shares, setShares] = useState('')
  const [price, setPrice] = useState({unit: 0, total: 0})
  const [tradeFail, setTradeFail] = useState(false)
  const [worth, setWorth] = useState(0)
  const {state, dispatch} = useStore()
  const [renewTime,setRenewTime] = useState(0)
  useEffect(() => {
    async function updateHoldings() {
      let holdList = []
      const newHoldings = (await axios.post(`${baseURL}/profile`, {token: state.token.data})).data
      setCash(newHoldings.cash)
      let tempWorth = cash
      newHoldings.holdingInfo.forEach((elem, index) => {
        holdList.push({
          ticker: elem.symbol,
          shares: elem.shares,
          price: newHoldings.price[index],
          invested: elem.shares * newHoldings.price[index]
        })
        tempWorth += elem.shares * newHoldings.price[index]
      })
      setCash(newHoldings.cash)
      setHoldings(holdList)
      setWorth(tempWorth)
    }

    updateHoldings()
    if (updateTick === 0) {
      setTimeout(() => {
        setUpdateTick(updateTick + 1)
      }, 10)
    } else {
      setTimeout(() => {
        setUpdateTick(updateTick + 1)
      }, 3000)
    }
  }, [updateTick])
  useEffect(()=>{
    async function renew() {
      const token = await axios.post(baseURL + '/renew', {token:state.token.data})
      if (token.data) {
        dispatch({type: "login", message: token})
        setToken(token.data)
      }
    }
    renew()
    setTimeout(()=>{setRenewTime(renewTime+1)},59*60*1000)
  },[renewTime])

  useEffect(() => {
    async function updatePrice() {
      const stockPrice = (await axios.post(`${baseURL}/getPrice`, {stock: ticker})).data
      if (stockPrice) {
        setPrice({unit: stockPrice, total: stockPrice * shares})
      }
    }

    updatePrice()
  }, [debouncedTicker])
  useEffect(() => {
    setPrice({unit: price.unit, total: price.unit * shares})
  }, [shares])
  return (
    <div>
      <div style={borderMaker}>
        <button onClick={() => {
          document.location = `${baseWebPage}/rankings`
        }}>
          View Rankings
        </button>
        <button onClick={() => {
          dispatch({type: 'logout'})
          document.location = baseWebPage
        }}>
          Logout
        </button>
      </div>
      <div style={borderMaker}>
        <div style={buyShitStyles}>
          <div>
            Make a trade:
          </div>
          <div>
            You are currently {buying ? 'buying' : 'selling'} stocks
          </div>
          <button onClick={() => setBuying(!buying)}>
            {buying ? 'Sell' : 'Buy'} instead
          </button>
          <div>
            Cash Remaining: {' $' + roundPrice(cash)}
          </div>
          Stock Symbol
          <div>
            <input type="text" onChange={event => setTicker(event.target.value)} value={ticker}/>
          </div>
          Shares {buying ? 'Purchased' : 'Sold'}
          <div>
            <input type="text" onChange={event => setShares(event.target.value)} value={shares}/>
          </div>
          Total Price : {roundPrice(price.total)} Single Share Price: {roundPrice(price.unit)}
          <div>
            <button onClick={async () => {
              async function makeTrade() {
                if (JSON.parse(shares)===Math.floor(JSON.parse(shares))&&shares>0) {
                  await axios.post(`${baseURL}/trade`, {
                    token: state.token.data,
                    symbol: ticker,
                    shares: buying ? shares : -1 * shares
                  })
                } else {alert('Number of shares traded must be a positive integer')}
              }
              const gainingCash = await axios.post(`${baseURL}/gaining`, {
                token: state.token.data,
                symbol: ticker,
                shares: buying ? shares : -1 * shares
              })
              if (Math.abs(price.total) < cash||gainingCash.data) {
                makeTrade()
                setTradeFail(false)
              } else {
                setTradeFail(true)
              }
            }}>
              Make Trade
            </button>
          </div>
          <div>
            {tradeFail ? 'You are too poor to make this trade' : null}
          </div>
        </div>
      </div>
      <div style={borderMaker}>
        <table style={tableStyles}>
          <tr>
            <td>
              My Holdings:
            </td>
          </tr>
          <tr>
            <td style={tdStyles}>
              Stock Symbol
            </td>
            <td style={tdStyles}>
              Shares Invested
            </td>
            <td style={tdStyles}>
              Share Price
            </td>
            <td style={tdStyles}>
              Money Invested
            </td>
          </tr>
          {holdings.map((elem) =>
            <tr>
              <td style={tdStyles}>
                {elem.ticker}
              </td>
              <td style={tdStyles}>
                {elem.shares < 0 ? 'short ' : null}{Math.abs(elem.shares)}
              </td>
              <td style={tdStyles}>
                {'$' + roundPrice(elem.price)}
              </td>
              <td style={tdStyles}>
                {'$' + Math.abs(roundPrice(elem.invested))}
              </td>
            </tr>
          )}
        </table>
      </div>
      <div style={borderMaker}>
        <button onClick={() => {
          document.location = `${baseWebPage}/pairing`
        }}>
          Want to pair your account with discord? Click here.
        </button>
      </div>
    </div>
  );
}

export default Profile;