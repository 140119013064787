import {HashRouter as Router, Route} from "react-router-dom";
import App from "./App";
import Profile from "./Profile";
import Rankings from "./Rankings";
import Pairing from "./Pairing";
import React, {useEffect} from "react";
import useLocalStorage from "./useLocalStorage";
import {useStore} from "./store";

export function Main() {
  const [token, setToken] = useLocalStorage('token','')
  const {state,dispatch} = useStore()

  useEffect(()=> {
    if (token) {
      dispatch({type: "login", message: {data:token}})
    }
  }, [])
  return <Router>
    <Route exact path={"/"}>
      <App/>
    </Route>
    <Route path={"/profile/"}>
      <Profile/>
    </Route>
    <Route path={"/rankings/"}>
      <Rankings/>
    </Route>
    <Route path={"/pairing/"}>
      <Pairing/>
    </Route>
  </Router>;
}