import './App.css';
import React, {useState, useEffect} from "react";
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {roundPrice} from "./roundPrice";
import {useStore} from "./store";
import {baseURL, baseWebPage, borderMaker, buyShitStyles, tableStyles, tdStyles} from "./utils";
import useLocalStorage from "./useLocalStorage";

function Rankings() {
  const [updateTick, setUpdateTick] = useState(0)
  const [ranks, setRanks] = useLocalStorage('rankObj',{allRanks: [], myRank: 0, myMoney: 0, myName: ''})
  const {state, dispatch} = useStore()
  const [renewTime,setRenewTime] = useState(0)
  const [token,setToken] = useLocalStorage('token','')
  useEffect(() => {
    async function getRanks() {
      const rankList = (await axios.post(`${baseURL}/rankings`, {token: state.token.data})).data
      setRanks(rankList)
    }
    getRanks()
    if (updateTick<2) {
      setTimeout(() => {
        setUpdateTick(updateTick + 1)
      }, 20)
    } else {
      setTimeout(() => {
        setUpdateTick(updateTick + 1)
      }, 2000)
    }
  }, [updateTick])
  useEffect(()=>{
    async function renew() {
      const token = await axios.post(baseURL + '/renew', {token:state.token.data})
      if (token.data) {
        dispatch({type: "login", message: token})
        setToken(token.data)
      }
    }
    renew()
    setTimeout(()=>{setRenewTime(renewTime+1)},59*60*1000)
  },[renewTime])
  return (
    <div>
      <div style={borderMaker}>
        <div style={borderMaker}>
          <button onClick={() => {
            document.location = `${baseWebPage}/profile`
          }}>
            View Profile
          </button>
          <button onClick={() => {
            dispatch({type: 'logout'})
            document.location = baseWebPage
          }}>
            Logout
          </button>
        </div>
        <div style={buyShitStyles}>
          My Status:
          <div>
            {ranks.myName}
          </div>
          <div>
            Rank: {ranks.myRank} Money: {'$' + roundPrice(ranks.myMoney)}
          </div>
        </div>
      </div>
      <div style={borderMaker}>
        <table style={tableStyles}>
          <tr>
            <td style={tdStyles}>
              Place
            </td>
            <td style={tdStyles}>
              Username
            </td>
            <td style={tdStyles}>
              Net Worth
            </td>
          </tr>
          {ranks.allRanks.map((elem, index) =>
            <tr>
              <td style={tdStyles}>
                {index + 1}
              </td>
              <td style={tdStyles}>
                {elem.user}
              </td>
              <td style={tdStyles}>
                {'$' + roundPrice(elem.money)}
              </td>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
}

export default Rankings;