import './App.css';
import React, {useState, useEffect} from "react";
import {useParams} from 'react-router-dom'
import axios from 'axios'

import {useStore} from "./store";
import {baseURL, baseWebPage} from "./utils";

function Pairing() {
  const [code, setCode] = useState('')
  const {state, dispatch} = useStore()
  useEffect(() => {
    async function getCode() {
      const newCode = (await axios.post(`${baseURL}/code`, {token: state.token.data})).data
      setCode(newCode)
    }

    getCode()
  }, [])
  return (
    <div>
      <button onClick={()=>{
        document.location=`${baseWebPage}/profile`
      }}>
        Back to Profile
      </button>
      <div>
        Dm {'/verify ' + code} to Stock Bot verify your discord account's connection to your account.
      </div>
    </div>
  );
}

export default Pairing;